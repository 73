import React from 'react'
import PropTypes from 'prop-types'
import BasicLayout from '@components/BasicLayout'
import ContentWrapper from '@components/ContentWrapper'
import Outline from '@components/Outline'
import unified from 'unified'
import rehypeSlug from 'rehype-slug'
import rehypeReact from '../util/hastRenderer'
import Slugger from 'github-slugger'
import { graphql } from 'gatsby'


const processor = unified()
  .use(rehypeSlug)
  .use(rehypeReact)

const compileAst = (ast) => processor.stringify(processor.runSync(ast))

const transformHeadings = (headings) => {
  const slugger = new Slugger()
  return headings.map((heading) => ({
    text: heading.value,
    level: heading.depth,
    slug: slugger.slug(heading.value)
  }))
}

const Page = ({
  data: {
    markdownRemark: node
  }
}) =>
  <BasicLayout title={node.frontmatter.title}>
    <ContentWrapper siderail={node.frontmatter.outline}>
      {compileAst(node.htmlAst)}
      {node.frontmatter.outline &&
        <Outline headings={transformHeadings(node.headings)} spy={true} />
      }
    </ContentWrapper>
  </BasicLayout>

Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        outline: PropTypes.bool.isRequired
      }).isRequired,
      headings: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        depth: PropTypes.number.isRequired
      }))
    })
  }).isRequired
}

export default Page

export const pageQuery = graphql`
  query (
    $slug: String!
    $outline: Boolean!
  ) {
    markdownRemark (
      frontmatter: {slug: {eq: $slug}}
    ) {
      htmlAst
      frontmatter {
        title
        outline
      }

      headings @include(if: $outline) {
        value
        depth
      }
    }
  }
`
