import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from './Layout'
import Hero from './Hero'

const BasicLayout = ({ title, children, ...props }) =>
  <Layout {...props}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Hero><h1>{title}</h1></Hero>
    {children}
  </Layout>

BasicLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default BasicLayout
